/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable operator-linebreak */
import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Autocomplete from '../autocomplete/Autocomplete';
import PlusIcon from '../../image/plusIcon.svg';
import SlackIcon from './img/slackIcon.svg';
import TelegramIcon from './img/telegramIcon.svg';
import EmailIcon from './img/emailIcon.svg';
import PhoneIcon from './img/phoneIcon.svg';
import WebhookIcon from './img/shareIcon.svg';
import WhatsappIcon from './img/whatsappIcon.svg';
import AlertIcon from './img/alertIcon.svg';
import MultiTagDropdown from '../MultiTagDropdown/MultiTagDropdown';

import './geofenceactionuser.css';

const tempListUser = [
    { id: 'John Doe', name: 'john.doe@asiamobiliti.com' },
    { id: 'Jane Doe', name: 'jane.doe@asiamobiliti.com' },
    { id: 'Astro Boy', name: 'astro.boy@asiamobiliti.com' },
];

export default function GeofenceActionUser(props) {
    const {
        users,
        newUserFunction,
        userCancel,
        disabledAddUser,
        saveUser,
        editUser,
        removeUser,
        listDropdown,
        setSelectedOption,
        selectedOption,
        loading,
        setDisabledAddUser,
        saveUpdateUser,
    } = props;

    const size = 3;
    const [selectionList, setSelectionList] = useState({});
    const [inputValue, setInputValue] = useState('');
    const [dropDownLoading, setDropDownLoading] = useState(false);
    const [selectedChannels, setSelectedChannels] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState({});

    const iconChannels = (value) => {
        switch (value) {
            case 'slack':
                return <img loading="lazy" src={SlackIcon} alt="slack-icon" style={{ width: '20px', height: '20px' }} />;
            case 'telegram':
                return <img loading="lazy" src={TelegramIcon} alt="telegram-icon" style={{ width: '20px', height: '20px' }} />;
            case 'email':
                return <img loading="lazy" src={EmailIcon} alt="email-icon" style={{ width: '20px', height: '20px' }} />;
            case 'phone':
                return <img loading="lazy" src={PhoneIcon} alt="phone-icon" style={{ width: '20px', height: '20px' }} />;
            case 'share':
                return <img loading="lazy" src={WebhookIcon} alt="webhook-icon" style={{ width: '20px', height: '20px' }} />;
            case 'whatsapp':
                return <img loading="lazy" src={WhatsappIcon} alt="whatsapp-icon" style={{ width: '20px', height: '20px' }} />;
            default:
                return null;
        }
    };

    const stringAvatar = (name) => {
        if (!name) {
            return {
                sx: {
                    backgroundColor: '#F2F4F7',
                    color: '#475467',
                    fontSize: '1rem',
                    fontWeight: 500,
                },
                alt: 'avatar-icon',
                children: 'NA',
            };
        }
        const initials = name.split(' ').map((n) => n[0]).join('');
        return {
            sx: {
                backgroundColor: '#F2F4F7',
                color: '#475467',
                fontSize: '1rem',
                fontWeight: 500,
            },
            alt: 'avatar-icon',
            children: initials,
        };
    };

    useEffect(() => {
        if (listDropdown[selectedOption]?.channel) {
            const initialChannels = listDropdown[selectedOption].channel
                .filter((channel) => channel.isEnabled)
                .map((channel) => channel.type.toLowerCase());
            setSelectedChannels(initialChannels);
        }
    }, [selectedOption, listDropdown]);

    const handleSaveUser = () => {
        const newUser = {
            ...selectionList,
            isNew: true,
            alertOn: selectedChannels.map((type) => ({ type })),
        };
        saveUser(newUser);
        setSelectedChannels([]);
        setSelectedOption(null);
    };

    return (
        <div className="g-details-action-user-container">
          <div className="g-details-action-user-header">
            <div>User</div>
            <div>Notification Channels</div>
          </div>
          <div>
            <div className="g-details-action-user-body-list-container">
              {users?.length > 0 &&
                users.map((user, index) => (
                  <div key={user.id} className="g-details-action-user-body">
                    <div className="g-details-action-user-avatar-body">
                      {user.url ? (
                        <Avatar alt="avatar-icon" src={user.url} />
                      ) : (
                        <Avatar {...stringAvatar(user.id)} />
                      )}
                      {editingIndex === index ? (
                        <div>
                          <div
                            className="g-details-action-user-body-name"
                            style={{ opacity: 0.5 }}
                          >
                            {user.id || ''}
                          </div>
                          <div
                            className="g-details-action-user-body-email"
                            style={{ opacity: 0.5 }}
                          >
                            {user.name}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="g-details-action-user-body-name">
                            {user.id || ''}
                          </div>
                          <div className="g-details-action-user-body-email">
                            {user.name}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="g-details-action-user-body-notification">
                      {editingIndex === index ? (
                        <div style={{ width: '100%', maxWidth: '360px' }}>
                          <MultiTagDropdown
                            isDisabled={false}
                            options={['Telegram', 'Email'].map((type) => ({
                              value: type.toLowerCase(),
                              label: type,
                              icon: type.toLowerCase() === 'telegram' ? TelegramIcon : EmailIcon,
                              isEnabled: listDropdown[selectedOption]?.channel.some(
                                (channel) => channel.type === type && channel.isEnabled,
                              ),
                            }))}
                            selectedTags={selectedChannels}
                            setSelectedTags={setSelectedChannels}
                            style={{
                              width: '100%',
                              minWidth: '200px',
                              boxSizing: 'border-box',
                            }}
                          />
                        </div>
                      ) : user.isNew && user.alertOn?.length > 0 ? (
                        <div className="gap">
                          {(user.alertOn || [])
                            .sort((a, b) => a.type.localeCompare(b.type))
                            .map((channel) => iconChannels(channel.type.toLowerCase()))}
                        </div>
                      ) : user.channel?.length > 0 ? (
                        <div className="gap">
                          {(user.channel || [])
                            .sort((a, b) => a.type.localeCompare(b.type))
                            .map((channel) => iconChannels(channel.type.toLowerCase()))}
                        </div>
                      ) : (
                        <div className="empty">
                          <img loading="lazy" src={AlertIcon} alt="alert-icon" />
                          <div>No Channels Configured</div>
                        </div>
                      )}
                    </div>
                    <div className="g-details-action-user-remove-button">
                      {editingIndex === index ? (
                        <>
                          <div
                            onClick={() => {
                              setEditingIndex(null);
                              setSelectedChannels([]);
                            }}
                            tabIndex="0"
                            role="button"
                            style={{ cursor: 'pointer' }}
                          >
                            Cancel
                          </div>
                          <div
                            className="g-detail-a-edit-btn"
                            onClick={() => {
                              const updatedUser = {
                                ...user,
                                channel: selectedChannels.map((type) => ({
                                  type,
                                })),
                                alertOn: selectedChannels.map((type) => ({
                                  type,
                                })),
                              };
                              saveUpdateUser(updatedUser, index);
                              setEditingIndex(null);
                              setSelectedChannels([]);
                            }}
                            tabIndex="0"
                            role="button"
                            style={{ cursor: 'pointer' }}
                          >
                            Save
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            onClick={() => removeUser(index, user)}
                            tabIndex="0"
                            role="button"
                            style={{ cursor: 'pointer' }}
                          >
                            Remove
                          </div>
                          <div
                            className={`g-detail-a-edit-btn ${disabledAddUser ? 'disabled' : ''}`}
                            onClick={() => {
                              if (!disabledAddUser) {
                                setEditingIndex(index);
                                const channels = user.channel?.map((channel) => channel.type.toLowerCase());
                                setSelectedChannels(channels || []);
                              }
                            }}
                            tabIndex="0"
                            role="button"
                            style={{
                              cursor: disabledAddUser ? 'not-allowed' : 'pointer',
                              opacity: disabledAddUser ? 0.5 : 1,
                            }}
                          >
                            Edit
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
            </div>
            {disabledAddUser && !editingIndex && (
              <div className="g-details-action-user-body-with-above-divider">
                <div>
                  <Autocomplete
                    autocompleteComponent="Geofence"
                    list={listDropdown}
                    text="Select Team Member"
                    isImage={false}
                    isAgencySelector={false}
                    inputValue={inputValue}
                    stateInputValue={setInputValue}
                    setSelectionList={setSelectionList}
                    selectionList={selectionList}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    dropDownLoading={dropDownLoading}
                    setDropDownLoading={setDropDownLoading}
                    loadAsset={loading}
                  />
                </div>
                <div>
                  <MultiTagDropdown
                    isDisabled={!listDropdown[selectedOption]}
                    options={['Telegram', 'Email'].map((type) => ({
                      value: type.toLowerCase(),
                      label: type,
                      icon: type === 'Telegram' ? TelegramIcon : EmailIcon,
                      isEnabled: listDropdown[selectedOption]?.channel.some(
                        (channel) => channel.type === type && channel.isEnabled,
                      ),
                    }))}
                    selectedTags={selectedChannels}
                    setSelectedTags={setSelectedChannels}
                  />
                </div>
                <div className="g-details-action-user-remove-button">
                  <div
                    onClick={() => {
                      setDisabledAddUser(false);
                      setInputValue('');
                      setSelectedChannels([]);
                    }}
                    tabIndex="0"
                    role="button"
                    style={{ cursor: 'pointer' }}
                  >
                    Cancel
                  </div>
                  <div
                    className="g-detail-a-edit-btn"
                    onClick={handleSaveUser}
                    tabIndex="0"
                    role="button"
                    style={{ cursor: 'pointer' }}
                  >
                    Save
                  </div>
                </div>
              </div>
            )}
            {/* Hide the "Add New User" button when the user is being edited */}
            {editingIndex === null && !disabledAddUser && (
              <div
                className="g-details-action-user-button"
                style={{
                  cursor: editingIndex !== null ? 'not-allowed' : 'pointer',
                  opacity: editingIndex !== null ? 0.5 : 1,
                }}
                role="button"
                onClick={() => {
                  setDisabledAddUser(true);
                  setInputValue('');
                  setSelectionList({});
                  setSelectedChannels([]);
                }}
                tabIndex={0}
              >
                <img loading="lazy" src={PlusIcon} alt="plus-icon" />
                <div>Add New User</div>
              </div>
            )}
          </div>
        </div>
      );
}
