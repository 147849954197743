import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const MultiTagDropdown = ({
  isDisabled, options, selectedTags, setSelectedTags,
}) => {
  const customOptionLabel = (data) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {data.icon && (
        <img
          src={data.icon}
          alt={data.label}
          style={{ width: '20px', marginRight: '5px' }}
        />
      )}
      {data.label}
    </div>
  );

  const handleChange = (selected) => {
    const updatedTags = selected ? selected.map((option) => option.value) : [];
    setSelectedTags(updatedTags);
  };

  return (
    <div className={`multi-tag-dropdown ${isDisabled ? 'isDisabled' : ''}`}>
      <Select
        options={options}
        isMulti
        value={options.filter((option) => selectedTags?.includes(option.value))}
        onChange={handleChange}
        placeholder="Select Notification Channels"
        classNamePrefix="select"
        getOptionLabel={customOptionLabel}
        isDisabled={isDisabled}
        menuPortalTarget={document.body}
        styles={{
          control: (base) => ({
            ...base,
            border: '1px solid #D0D5DD',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            borderRadius: '0.5rem',
            padding: '0.20rem 0.45rem',
            minHeight: '30px',
            backgroundColor: isDisabled ? '#f9f9f9' : '#fff',
            width: '360px',
            maxWidth: '100%',
            outline: 'none',
          }),
          valueContainer: (base) => ({
            ...base,
            padding: '0 4px',
          }),
          multiValue: (base) => ({
            ...base,
            backgroundColor: '#FFFFFF',
            border: '1px solid #D0D5DD',
            borderRadius: '0.5rem',
          }),
          multiValueLabel: (base) => ({
            ...base,
            fontSize: '15px',
            lineHeight: '20px',
            gap: '0.4rem',
            background: '#FFFFFF',
            borderRadius: '6px',
            fontFamily: "'Inter', sans-serif",
            fontStyle: 'normal',
            fontWeight: 500,
            color: '#000000',
          }),
          placeholder: (base) => ({
            ...base,
            fontSize: '1rem',
            color: isDisabled ? '#b3b3b3' : '#667085',
          }),
          option: (base, state) => ({
            ...base,
            padding: '0.25rem 0.25rem',
            margin: '2.5px 4px',
            fontSize: '15px',
            lineHeight: '1.5rem',
            gap: '0.4rem',
            fontFamily: "'Inter', sans-serif",
            fontStyle: 'normal',
            fontWeight: '500',
            color: '#000000',
            backgroundColor: state.isSelected || state.isFocused ? '#F9FAFB' : '#FFFFFF',
            borderRadius: '2px',
            boxSizing: 'border-box',
            width: 'calc(100% - 8px)',
            ':hover': {
              backgroundColor: '#F9FAFB',
            },
            ':active': {
              backgroundColor: '#F9FAFB',
            },
          }),
          menu: (base) => ({
            ...base,
            border: '1px solid #D0D5DD',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            borderRadius: '0.5rem',
            width: '360px',
            zIndex: 9999,
          }),
          multiValueRemove: (base) => ({
            ...base,
            color: '#344054',
            cursor: 'pointer',
            ':hover': {
              backgroundColor: 'transparent',
              color: '#000000',
            },
          }),
        }}
      />
    </div>
  );
};

export default MultiTagDropdown;
