/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { isEqual } from 'lodash';
import * as turf from '@turf/turf';
// import { DrawPolygonMode } from 'react-map-gl-draw';
import { PrimaryButton, SecondaryButton } from '../buttonComponent/ButtonComponent';
import Text from '../text/Text';
import Checkbox from '../checkbox/Checkbox';
import InputTags from '../inputTags/InputTags';
import InputText from '../inputText/InputText';
import MapGeofence from '../mapGeofence/MapGeofence';
import GeofenceActionUser from '../actionUser/GeofenceActionUser';
import AreaNameList from '../../panel/inventory/geofence/AreaNameList';
import TextArea from '../textAreaLimit/TextArea';
// import BackArrowIcon from '../../../image/backArrow.svg';
import './GeofenceAlert.css';
import { APIGetV3TeamMembers } from '../../config/restAPI/FetchAPI';
import { APICreateAlertPolicy } from '../../config/restAPI/PostAPI';
import { APIPatchAlertPolicy } from '../../config/restAPI/PatchAPI';

// const DrawPolygon = { id: 'drawPolygon', text: 'Draw Polygon', Handler: DrawPolygonMode };

export default function GeofenceAlert(props) {
  // #region props and states
  const { detailsRow, bboxPolygon, selectionList } = props;
  const token = Cookies.get('jwtToken');
  const agencyID = Cookies.get('agencyUUID');
  // console.log('detailsRowwww', detailsRow);
  const [startDraw, setStartDraw] = useState(null); // this state to activate the polygon draw library
  const [disabledSaveButton, setDisabledSaveButton] = useState(true); // this state are for disabled button in table list (areas and notified users)
  const [nameList, setNameList] = useState(''); // this state for onChange on area name list.
  const [selectedPolygon, setSelectedPolygon] = useState([]);
  const [selectedListPolygon, setSelectedListPolygon] = useState([]);

  const [activatePolygonClick, setActivatePolygonClick] = useState(false);
  const [editPolygonIndex, setEditPolygonIndex] = useState(null); // index polygon
  const [editPolygonDetails, setEditPolygonDetails] = useState(null); // edit selected polygon details
  const [modeHandler, setModeHandler] = useState(null);
  const [fullSelectedPolygon, setFullSelectedPolygon] = useState(null);
  const [teamList, setTeamList] = useState([]);

  const [userRemove, setUserRemove] = useState([]); // this for update remove user
  const [userAdd, setUserAdd] = useState([]); // this for update add user

  const [polygonAdd, setPolygonAdd] = useState([]); // this for update add polygon
  const [polygonEdit, setPolygonEdit] = useState([]);
  const [polygonRemove, setPolygonRemove] = useState([]); // this for update remove polygon

  const [disabledAddUser, setDisabledAddUser] = useState(false);
  const [disabledAddPolygon, setDisabledAddPolygon] = useState(false);
  const [selectionUser, setSelectionUser] = useState({});
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(null);
  const [onDrawPolygon, setOnDrawPolygon] = useState(false);
  const [polygonList, setPolygonList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [fitboundEDit, setFitboundEDit] = useState({});
  const navigate = useNavigate();
  const [editingIndex, setEditingIndex] = useState(null);

  const [disableMainButton, setDisableMainButton] = useState(false);

  const [addGeofenceValue, setAddGeofenceValue] = useState({
    alertName: detailsRow && detailsRow.alertName ? detailsRow.alertName : '',
    notification: detailsRow && detailsRow.notification && Object.keys(detailsRow.notification).length > 0 ? {
      enter: detailsRow.notification.enter,
      leave: detailsRow.notification.leave,
      generatePin: detailsRow.notification.generatePin,
    } : {
      enter: false,
      leave: false,
      generatePin: false,
    },
  });

  const [descriptionValue, setDescriptionValue] = useState(detailsRow && detailsRow.description ? detailsRow.description : '');
  const [listUser, setListUser] = useState(detailsRow && detailsRow.notifiedUsers && detailsRow.notifiedUsers.length > 0 ? detailsRow.notifiedUsers : []);
  const [actualListPolygon, setActualListPolygon] = useState(detailsRow && detailsRow.geofenceArea.length > 0 ? detailsRow.geofenceArea : []);

  const [tags, setTags] = useState(detailsRow && detailsRow.associatedTags && detailsRow.associatedTags.length > 0 ? detailsRow.associatedTags : []);
  const [removeTags, setRemoveTags] = useState([]); // this for update remove tags
  const [tagsHistory, setTagsHistory] = useState([]);
  const [polygonListState, setPolygonListState] = useState(null);

  const [loading, setLoading] = useState(false);
  const pageTotal = { totalPerPage: 100 };
  // #endregion

  // input onChange on Polycies Name
  const inputOnChangeGeofenceName = (e) => {
    setAddGeofenceValue({ ...addGeofenceValue, alertName: e.target.value });
  };

  // input onChange on Area name list
  const inputGeofenceAreaName = (e) => {
    setNameList(e.target.value);
  };

  useEffect(() => {
    // console.log('startDraw', detailsRow);
    // console.log(actualListPolygon);

    // this on edit page.
    if (startDraw) {
      actualListPolygon.map((x) => startDraw.add(x.area[0]));
    }
  }, [detailsRow, startDraw]);

  // back button at the top
  const backButton = () => {
    navigate('/notifications/configuration', { replace: true });
    setListUser([]);
  };

  // #region disabledButton check -----------------------------------
  // this indicator for save button disabled for Areas and Notified Users field
  useEffect(() => {
    if (detailsRow) {
      if (editPolygonDetails && Object.keys(editPolygonDetails).length > 0) {
        setDisabledSaveButton(false);
      } else {
        setDisabledSaveButton(true);
      }
      if (selectedPolygon.length > 0 && nameList) {
        setDisabledSaveButton(false);
      } else {
        setDisabledSaveButton(true);
      }
    } else if (selectedPolygon.length > 0 && nameList) {
        setDisabledSaveButton(false);
      } else {
        setDisabledSaveButton(true);
      }
  }, [nameList, selectedPolygon, editPolygonDetails]);
  // #endregion -----------------------------------------------

  // #region API Function and useEffect --------------------------------------------
  const fetchTeam = async (value) => {
    try {
      const result = await APIGetV3TeamMembers(setLoading, token, agencyID, value);
      // console.log('result', result);
      if (result.data.status === 200) {
        const { data } = result.data;
        if (data.length > 0) {
          const reducer = data.map((x) => ({
            id: x.userFirstName || x.userLastName ? `${x.userFirstName} ${x.userLastName}` : '',
            name: x.userEmail,
            url: x?.profileImageUrl ? x.profileImageUrl : null,
            channel: x?.channels?.length > 0 ? x.channels : [],
            oid: x.oid,
          }));
          if (listUser.length > 0) {
            const ids = new Set(listUser.map((r) => r.oid));
            const updatedUser = reducer.filter((u) => !ids.has(u.oid));
            setTeamList(updatedUser);
          } else {
            setTeamList(reducer);
          }
        } else {
          console.log('No data');
        }
        setLoading(false);
    }
    } catch (error) {
      setLoading(false);
    }
  };

//! Need to update CreateGeofence API
  const createGeofence = async (value) => {
    try {
      setDisableMainButton(true);
      const result = await APICreateAlertPolicy(token, setLoading, agencyID, value);
      if (result?.data?.status === 200) {
        setLoading(false);
        setDisableMainButton(false);
        navigate('/notifications/configuration', { state: { snackbarStatus: 'success', snackbarTitle: 'Success', snackbarSub: 'New geofence created!' } });
      } else {
        setLoading(false);
        setDisableMainButton(false);
        navigate('/notifications/configuration', { state: { snackbarStatus: 'error', snackbarTitle: 'Error', snackbarSub: `${result.response.data.status}: ${result.response.data.error.message}` } });
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setDisableMainButton(false);
    }
  };

//! Need to update UpdateGeofence API
  const updateGeofence = async (value) => {
    try {
      setDisableMainButton(true);
      const result = await APIPatchAlertPolicy(token, setLoading, agencyID, value);
      if (result.data.status === 200) {
        setLoading(false);
        setDisableMainButton(false);
        navigate('/notifications/configuration', { state: { snackbarStatus: 'success', snackbarTitle: 'Success', snackbarSub: 'Geofence Updated!' } });
      } else {
        setDisableMainButton(false);
        setLoading(false);
        navigate('/notifications/configuration', { state: { snackbarStatus: 'error', snackbarTitle: 'Error', snackbarSub: `${result.error.code}:${result.error.message}` } });
      }
    } catch (err) {
      setDisableMainButton(false);
      console.log('Update Error');
      setLoading(false);
    }
  };

  // once click save button in notified users
  useEffect(() => {
    if (listUser.length > 0) {
      fetchTeam(pageTotal);
    }
  }, [listUser]);

  // first load fetchTeam
  useEffect(() => {
    if (token) {
      fetchTeam(pageTotal);
    }
  }, []);
  // #endregion -------------------------------------------------------

  // #region Areas function field --------------------------------------------------
  // this useEffect for when user click polygon will trigger the edit button
  useEffect(() => {
    if (activatePolygonClick) {
      if (selectedPolygon.length > 0) {
        const filterPolygon = actualListPolygon.filter((x) => x.area[0].id === selectedPolygon[0]);
        const indexPolygon = actualListPolygon.findIndex((x) => x.area[0].id === selectedPolygon[0]);
        if (filterPolygon.length > 0) {
          setEditPolygonDetails(filterPolygon);
          setEditPolygonIndex(indexPolygon);
          setNameList(filterPolygon[0].name);
          // console.log({
          //   filterPolygon, selectedPolygon, actualListPolygon, indexPolygon,
          // });
        }
      }
      setActivatePolygonClick(false);
      // setPolygonListState('edit');
    }
  }, [activatePolygonClick]);

  // useEffect(() => {
  //   console.log('activate hahahha', {
  //     editPolygonDetails, editPolygonIndex,
  //   });
  // }, [editPolygonDetails, editPolygonIndex]);

  // this when click Draw New Area button in Areas list
  const polygonFunction = (id) => {
    try {
      const emptyPolygon = {
        id: '',
        area: [],
        name: '',
      };
      startDraw.changeMode('draw_polygon');
      setSelectedListPolygon(emptyPolygon);
      setDisabledAddPolygon(true);
    } catch (err) {
      // console.log('errorrrrr', err);
    }
  };

  // this when click edit button in Area list
  const editButton = (value, index) => {
    // console.log('editbutton', value);
    startDraw.changeMode('simple_select', { featureIds: value.area[0].id });
    setEditPolygonIndex(index); // this for AreaNameList component
    setEditPolygonDetails(value); // this is for AreaNameList component
    setFitboundEDit(value);
    setSelectedPolygon([value.area[0].id]); // this value to select the polygon
    setFullSelectedPolygon(value); //  this full selected for add / edit / remove state
    setNameList(value.name); // this for name
  };

  // useEffect(() => {
  //   console.log('fullSelectedPolygon', fullSelectedPolygon);
  // }, [fullSelectedPolygon]);

  // this when click cancel in Area list
  const cancelList = () => {
    // console.log('editPolygonDetails', {
    //   editPolygonDetails,
    // });
    // console.log('actualPolygonList', actualListPolygon);
    startDraw.changeMode('simple_select', { featureIds: '' });
    // setFullSelectedPolygon(actualListPolygon);
    startDraw.set({
      type: 'FeatureCollection',
      features: actualListPolygon.map((x) => x.area[0]),
    });
    setNameList('');
    setSelectedListPolygon({});
    setPolygonList([]);
    setFitboundEDit({});
    setDisabledAddPolygon(false);
    setEditPolygonDetails(null);
    setEditPolygonIndex(null);
  };

  // this function save list from Areas field (polygon draw list)
  const saveList = (value) => {
    if (value === 'add') {
      const reducer = polygonList
      && polygonList.map((x) => (x.id ? ({
        oid: x.id,
        name: nameList,
        area: polygonList,
      }) : ({
        name: nameList,
        area: polygonList,
      })));
      // console.log('aaaaaaaaa', {
      //   reducer, polygonList,
      // });
      setActualListPolygon([...actualListPolygon, reducer[0]]);
      setDisabledAddPolygon(false);
      startDraw.changeMode('simple_select', { featureIds: '' });
      if (detailsRow) {
        setPolygonAdd([...polygonAdd, reducer[0]]);
      }
    } else {
      setEditPolygonDetails(null);
      setEditPolygonIndex(null);

      const removedSelectedPolygon = actualListPolygon.filter((x) => x.area[0].id !== selectedPolygon[0]); // return non selectedPolygon list
      const updatedReducer = fullSelectedPolygon.area.map((x) => (x.id ? ({
        oid: x.id,
        name: nameList,
        area: [x],
      }) : ({
        name: nameList,
        area: [x],
      })));
      // console.log('removedSelectedPolygon', removedSelectedPolygon);
      // console.log('updatedReducer', updatedReducer);
      if (removedSelectedPolygon.length > 0) {
        // const combine = removedSelectedPolygon.concat(updatedReducer);
        const newArrays = updatedReducer.map((x) => {
          const index = removedSelectedPolygon.findIndex((e) => e.oid === x.oid);
          removedSelectedPolygon[index] = x;
          return removedSelectedPolygon;
        });
        // console.log(newArrays[0]);
        setActualListPolygon(newArrays[0]);

        // console.log({
          // fullSelectedPolygon, actualListPolygon, removedSelectedPolygon, combine,
        // });
      } else {
        setActualListPolygon(updatedReducer);
        // console.log({
        //   fullSelectedPolygon, actualListPolygon, removedSelectedPolygon, updatedReducer,
        // });
      }

      // console.log('updatedReducer', updatedReducer);
      if (detailsRow) {
        if (polygonEdit.length > 0) {
          const checkIfExistingEdit = polygonEdit.filter((x) => x.area[0].id !== selectedPolygon[0]);
          // console.log(console.log('checkIfExisting', checkIfExistingEdit));
          if (checkIfExistingEdit.length > 0) {
            // console.log('go here if value from row and polygonedi exist');
            const combine = checkIfExistingEdit.concat(updatedReducer);
            setPolygonEdit(combine);
          } else {
            // console.log('go here if value from row and plygonedit not exist');
            setPolygonEdit([...polygonEdit, updatedReducer[0]]);
          }
        } else {
          // const updateOnlyChanges = {

          // }
          setPolygonEdit([...polygonEdit, updatedReducer[0]]);
        }
      }
    }
    setDisabledAddPolygon(false);
    startDraw.changeMode('simple_select', { featureIds: '' });
    setSelectedListPolygon({});
    setPolygonList([]);
    setNameList('');
  };

  // this need to check if needed or not
  const onSelect = (options) => {
    setSelectedFeatureIndex(options.selectedFeatureIndex);
  };

  const deleteButton = (value, index) => {
    if (detailsRow) {
      // this to check if new addede and removed the
      const removeNewOneAdd = polygonAdd.filter((x) => x.area[0].id === value.area[0].id);
      if (removeNewOneAdd.length > 0) {
        const removePolygonAdd = polygonAdd.filter((x) => x.area[0].id !== value.area[0].id);
        setPolygonAdd(removePolygonAdd);
      } else {
        setPolygonRemove([...polygonRemove, value]);
      }
    }
    startDraw.delete(value.area[0].id);
    const removeSelectedPoly = [...actualListPolygon];
    if (removeSelectedPoly !== -1) {
      removeSelectedPoly.splice(index, 1);
      setActualListPolygon(removeSelectedPoly);
    }
  };

  // #endregion ------------------------------------------------------------

  // #region NotifiedUser function field -----------------------------------------------

  // saveUser in NotifiedUsers
  const saveUserButton = (selectionValue) => {
    if (detailsRow) {
      if (userAdd.length > 0) {
        const filterAdd = userAdd.filter((x) => x.oid === selectionValue.oid);
        if (filterAdd.length > 0) {
          setUserAdd(filterAdd);
        } else {
          setUserAdd([...userAdd, selectionValue]);
        }
      } else {
        setUserAdd([...userAdd, selectionValue]);
      }
    }
    const userWithAlertOn = {
      ...selectionValue,
      alertOn: selectionValue.alertOn || [],
    };
    setListUser([...listUser, userWithAlertOn]);
    setSelectionUser({});
    setDisabledAddUser(false);
  };

  const saveUpdateUserButton = (selectionValue, index) => {
    if (!selectionValue) return;
    if (detailsRow) {
        const existingUserIndex = userAdd.findIndex((x) => x.oid === selectionValue.oid);
        if (existingUserIndex !== -1) {
            const updatedUserAdd = [...userAdd];
            updatedUserAdd[existingUserIndex] = selectionValue;
            setUserAdd(updatedUserAdd);
        } else {
            setUserAdd([...userAdd, selectionValue]);
        }
    }
    const updatedListUser = [...listUser];
    if (index !== -1) {
        updatedListUser[index] = {
            ...updatedListUser[index],
            ...selectionValue,
            alertOn: selectionValue.alertOn || [],
        };
        setListUser(updatedListUser);
    }
    setSelectionUser({});
    setDisabledAddUser(false);
  };

  // useEffect(() => {
  //   console.log('listUser', listUser);
  // }, [listUser]);

  // onClick Add New User in notifiedUser field
  const addNewUser = () => {
    const user = {
      id: '',
      url: '',
      name: '',
      channels: [],
    };
    setSelectionUser(user);
    setDisabledAddUser(true);
  };

  // onClick remove in notifiedUser field
  const removeUser = (index, tagsValue) => {
    if (detailsRow) {
      if (userRemove.length > 0) {
        const filterRemove = userRemove.filter((x) => x.oid === tagsValue.oid);
        if (filterRemove.length > 0) {
          setUserRemove(filterRemove);
        } else {
          setUserRemove([...userRemove, tagsValue]);
        }
      } else {
        setUserRemove([...userRemove, tagsValue]);
      }
    }
    const removeArray = [...listUser];
    if (index !== -1) {
      removeArray.splice(index, 1);
      setListUser(removeArray);
    }
  };

  const editUser = (index, updatedUser) => {
    if (updatedUser) {
        const updatedListUser = [...listUser];
        if (index !== -1) {
            updatedListUser[index] = {
                ...updatedListUser[index],
                ...updatedUser,
                alertOn: updatedUser.channel || [],
            };
            setListUser(updatedListUser);
            console.log('This is listUser --->', listUser);
        }
        setSelectionUser({});
        setDisableMainButton(false);
    }
};

  // onClick cancel in notifiedUser field
  const userCancel = () => {
    setEditingIndex(null);
    setSelectionUser({});
    setDisabledAddUser(false);
  };
  // #endregion ------------------------------------------------------------

  // #region Update / Create button (MAIN) -------------------------------------------
  const checkingReducer = () => {
    const value = { alertPolicy: {} };
    console.log('This is value -->', value);
    value.alertPolicy.oid = detailsRow.oid;

    if (addGeofenceValue.alertName !== detailsRow.alertName) {
      value.alertPolicy.name = addGeofenceValue.alertName;
    }
    if (descriptionValue !== detailsRow.description) {
      value.alertPolicy.description = descriptionValue;
    }
    if (!isEqual(addGeofenceValue.notification, detailsRow.notification)) {
      if (!value.alertPolicy.geofence) value.alertPolicy.geofence = {};
      value.alertPolicy.geofence.notification = addGeofenceValue.notification;
    }
    if (userRemove.length > 0) {
      value.alertPolicy.removeNotifiedUsers = userRemove.map((x) => x.oid);
    }
    if (userAdd.length > 0) {
      value.alertPolicy.addNotifiedUsers = userAdd.map((x) => ({
        userOid: x.oid,
        alertOn: x.alertOn || [],
      }));
    }
    if (tags.length > 0) {
      value.alertPolicy.addAssociatedTags = tags;
    }
    if (removeTags.length > 0) {
      value.alertPolicy.removeAssociatedTags = removeTags;
    }
    if (polygonAdd.length > 0) {
      if (!value.alertPolicy.geofence) value.alertPolicy.geofence = {};
      value.alertPolicy.geofence.addGeofenceArea = polygonAdd.map((x) => ({
        name: x.name,
        area: x.area[0],
      }));
    }
    if (polygonRemove.length > 0) {
      if (!value.alertPolicy.geofence) value.alertPolicy.geofence = {};
      value.alertPolicy.geofence.removeGeofenceArea = polygonRemove.map((x) => x.oid);
    }
    if (polygonEdit.length > 0) {
      if (!value.alertPolicy.geofence) value.alertPolicy.geofence = {};
      value.alertPolicy.geofence.geofenceArea = polygonEdit.map((x) => ({
        oid: x.oid,
        name: x.name,
        area: x.area[0],
      }));
    }
    return value;
  };

  const checkingButtonDisabled = () => {
    const value = {};
    if (addGeofenceValue.alertName !== detailsRow.alertName) {
      value.alertName = addGeofenceValue.alertName;
    }
    if (descriptionValue !== detailsRow.description) {
      value.description = descriptionValue;
    }
    if (!isEqual(addGeofenceValue.notification, detailsRow.notification)) {
      if (!value.geofence) value.geofence = {};
      value.geofence.notification = addGeofenceValue.notification;
    }
    if (userRemove.length > 0) {
      value.removeUsers = userRemove.map((x) => x.oid);
    }
    if (userAdd.length > 0) {
      value.addUsers = userAdd.map((x) => ({
        userOid: x.oid,
        alertOn: x.alertOn || [],
      }));
    }
    if (!isEqual(tags, detailsRow.associatedTags)) {
      value.addAssociatedTags = tags;
    }
    if (removeTags.length > 0) {
      value.removeAssociatedTags = removeTags;
    }
    if (polygonAdd.length > 0) {
      if (!value.geofence) value.geofence = {};
      value.geofence.addPolygons = polygonAdd;
    }
    if (polygonRemove.length > 0) {
      if (!value.geofence) value.geofence = {};
      value.geofence.removePolygons = polygonRemove;
    }
    if (polygonEdit.length > 0) {
      if (!value.geofence) value.geofence = {};
      value.geofence.geofenceArea = polygonEdit;
    }

    const checking = Object.keys(value).length > 0;
    return checking;
  };

  useEffect(() => {
    if (detailsRow) {
      if (checkingButtonDisabled()) {
        setDisableMainButton(true);
      } else {
        setDisableMainButton(false);
      }
    } else if (addGeofenceValue.alertName) {
        setDisableMainButton(true);
      } else {
        setDisableMainButton(false);
      }
    }, [addGeofenceValue, tags, listUser, actualListPolygon, descriptionValue]);

  // #endregion ----------------------------------------------------------

  // #region  CreateGeofence and UpdateGeofence button API -------------------------

  // called CreateGeofence or UpdateGeofence API
  const finalCreateButton = () => {
    try {
      if (detailsRow) {
        const updateList = checkingReducer();
        updateGeofence(updateList);
      } else {
        const reducer = {
          alertPolicy: {
            name: addGeofenceValue.alertName,
            isEnabled: true,
            notifiedUsers: listUser.length > 0
              ? listUser.map((x) => ({
                  userOid: x.oid,
                  alertOn: x.alertOn || [],
                }))
              : [],
            associatedTags: tags,
            geofence: {
              geofenceArea: actualListPolygon.length > 0
                ? actualListPolygon.map((x) => ({
                    name: x.name,
                    area: x.area[0],
                  }))
                : [],
              notification: addGeofenceValue.notification,
            },
            alertType: selectionList.name,
            agencyOid: agencyID,
            description: descriptionValue,
          },
        };
        createGeofence(reducer);
      }
    } catch (err) {
      console.log('err', err);
    }
  };
// #endregion -------------------------------------------------------------------

  return (
    <div className="geofence-alert-details-container">
      <div className="geofence-alert-details-policy-container">
            <Text
              text="Alert Policy Name"
              weight="500"
              size="0.875rem"
              height="1.25rem"
              color="#344054"
            />
            <InputText placeholder="" value={addGeofenceValue.alertName} onChange={inputOnChangeGeofenceName} width="32rem" />
      </div>
      <div className="geofence-alert-details-description-container">
        <div className="configuration-body-row">
            <div className="configuration-text-container">
                <div className="configuration-main-text">
                    Description
                </div>
                <div className="configuration-supporting-text">
                    Add a brief description to further identify the alert or for specific notes
                </div>
            </div>
            <TextArea descriptionValue={descriptionValue} setDescriptionValue={setDescriptionValue} />
        </div>
      </div>
      <div className="geofence-alert-details-areas-container">
        <Text
          text="Areas"
          weight="500"
          size="0.875rem"
          height="1.25rem"
          color="#344054"
        />
        <div className="geofence-alert-details-areas-map">
          <div>
            <MapGeofence
              setStartDraw={setStartDraw}
              startDraw={startDraw}
              setPolygonList={setPolygonList}
              setSelectedPolygon={setSelectedPolygon}
              modeHandler={modeHandler}
              onUpdate={editButton}
              onSelect={onSelect}
              selectedFeatureIndex={selectedFeatureIndex}
              // polygonList={polygonList}
              fitboundEDit={fitboundEDit}
              setFitboundEDit={setFitboundEDit}
              actualListPolygon={actualListPolygon}
              setActivatePolygonClick={setActivatePolygonClick}
              setEditPolygonDetails={setEditPolygonDetails}
              setEditPolygonIndex={setEditPolygonIndex}
              setFullSelectedPolygon={setFullSelectedPolygon}
              bboxPolygon={bboxPolygon}
            />
          </div>
          <div>
            <AreaNameList
              selectedListPolygon={selectedListPolygon}
              disabledAddPolygon={disabledAddPolygon}
              createPolygonFunction={polygonFunction}
              cancelList={cancelList}
              editList={editButton}
              deleteList={deleteButton}
              saveList={saveList}
              inputChange={inputGeofenceAreaName}
              actualListPolygon={actualListPolygon}
              disabledSaveButton={disabledSaveButton}
              editPolygonDetails={editPolygonDetails}
              editPolygonIndex={editPolygonIndex}
              nameList={nameList}
              setPolygonListState={setPolygonListState}
            />
          </div>
        </div>
      </div>
      <div className="geofence-alert-details-areas-container">
        <div>
          <Text
            text="Asset/Device Tags"
            weight="500"
            size="0.875rem"
            height="1.25rem"
            color="#344054"
          />
          <Text
            text="Grant permission to tagged resources. Leave blank to grant permission to all resources"
            weight="400"
            size="0.875rem"
            height="1.25rem"
            color="#475467"
          />
        </div>
        <InputTags
          tags={tags}
          setTags={setTags}
          removedTags={removeTags}
          setRemovedTags={setRemoveTags}
          tagsHistory={tagsHistory}
        />
      </div>
      <div className="geofence-alert-details-areas-container">
        <Text
          text="Actions"
          weight="500"
          size="0.875rem"
          height="1.25rem"
          color="#344054"
        />
        <div>
          <div
            className="g-detail-action-checkbox-container"
            style={{ marginBottom: '16px' }}
          >
            <div style={{ position: 'relative', top: '3px' }}>
              <Checkbox
                width="16px"
                height="16px"
                borderRadius="4px"
                name="enter"
                checked={addGeofenceValue.notification.enter}
                onChange={(e) => setAddGeofenceValue({ ...addGeofenceValue, notification: { ...addGeofenceValue.notification, enter: e.target.checked } })}
              />
            </div>
            <div>
              <Text
                text="Notify Upon Entering"
                weight="500"
                size="0.875rem"
                height="1.25rem"
                color="#344054"
              />
              <div className="g-detail-action-checkbox-text-header">
                Triggers a notification when the asset / devices
                {' '}
                <b>enters</b>
                {' '}
                the geofenced area(s)
              </div>
            </div>
          </div>
          <div
            className="g-detail-action-checkbox-container"
            style={{ marginBottom: '16px' }}
          >
            <div style={{ position: 'relative', top: '3px' }}>
              <Checkbox
                width="16px"
                height="16px"
                borderRadius="4px"
                name="leave"
                checked={addGeofenceValue.notification.leave}
                onChange={(e) => setAddGeofenceValue({ ...addGeofenceValue, notification: { ...addGeofenceValue.notification, leave: e.target.checked } })}
              />
            </div>
            <div>
              <Text
                text="Notify Upon Leaving"
                weight="500"
                size="0.875rem"
                height="1.25rem"
                color="#344054"
              />
              <div className="g-detail-action-checkbox-text-header">
                Triggers a notification when the asset / devices
                {' '}
                <b>leaves</b>
                {' '}
                the geofenced area(s)
              </div>
            </div>
          </div>
          <div className="g-detail-action-checkbox-container">
            <div style={{ position: 'relative', top: '3px' }}>
              <Checkbox
                width="16px"
                height="16px"
                borderRadius="4px"
                name="generatePin"
                checked={addGeofenceValue.notification.generatePin}
                onChange={(e) => setAddGeofenceValue({ ...addGeofenceValue, notification: { ...addGeofenceValue.notification, generatePin: e.target.checked } })}
              />
            </div>
            <div>
              <Text
                text="Generate Smart Lock Pin"
                weight="500"
                size="0.875rem"
                height="1.25rem"
                color="#344054"
              />
              <div className="g-detail-action-checkbox-text-header">
                Triggers the generation of a smart lock PIN when asset / device
                enters the geofenced area(s)
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="geofence-alert-details-areas-container">
        <div>
          <Text
            text="Notified Users"
            weight="500"
            size="0.875rem"
            height="1.25rem"
            color="#344054"
          />
          <Text
            text="Select users to be notified when selected action(s) have been triggered"
            weight="400"
            size="0.875rem"
            height="1.25rem"
            color="#475467"
          />
        </div>
        <GeofenceActionUser
          users={listUser}
          listDropdown={teamList}
          newUserFunction={addNewUser}
          userCancel={userCancel}
          disabledAddUser={disabledAddUser}
          alignment="flex-end"
          saveUser={saveUserButton}
          saveUpdateUser={saveUpdateUserButton}
          editUser={editUser}
          removeUser={removeUser}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          loading={loading}
          editingIndex={editingIndex}
          setDisabledAddUser={setDisabledAddUser}
        />
      </div>
      <div className="g-detail-button-container">
        <div className="geofence-alert-details-button">
          <SecondaryButton label="Cancel" onClickFunction={backButton} />
          <PrimaryButton label={detailsRow ? 'Update' : 'Create'} disable={!disableMainButton} loading={loading} onClickFunction={finalCreateButton} />
        </div>
      </div>
    </div>
  );
}
