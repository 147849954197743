/* eslint-disable consistent-return */
import React, { useEffect, useState, useContext } from 'react';
import { Base64 } from 'js-base64';
import Cookies from 'js-cookie';
import { publicIpv4 } from 'public-ip';
import { useNavigate } from 'react-router-dom';
import { UAParser } from 'ua-parser-js';
import { useMediaQuery } from 'react-responsive';
import './login.css';
import axios from 'axios';
import Title from '../../component/title/Title';
import TextInput from '../../component/textInput/TextInput';
import Text from '../../component/text/Text';
import { TextButton, PrimaryButton, GoogleButton } from '../../component/buttonComponent/ButtonComponent';
import { AuthContext } from '../../config/reducer/Auth';
import HeaderImage from '../../image/header.svg';
import AMLogo from '../../image/AM.png';
import { APIPostLogin, APIWSConnect } from '../../config/restAPI/PostAPI';
import asiamobiliti from './img/asiamobiliti.png';
// import LoginImg from './img/loginimage.svg';
import SnackBar from '../../component/snackbar/Snackbar';
import Checkbox from '../../component/checkbox/Checkbox';

const getUserAgent = (userAgent, setDevice) => {
    // console.log(userAgent);
    if (userAgent.device.model === 'Macintosh') {
        setDevice({
            model: 'Mac OS X',
            type: 'desktop',
        });
    } else if (userAgent.os.name === 'Windows') {
        setDevice({
            model: `${userAgent.os.name} ${userAgent.os.version} PC`,
            type: 'desktop',
        });
    } else if (userAgent.os.name === 'Chromium OS') {
        setDevice({
            model: userAgent.os.name,
            type: 'desktop',
        });
    } else if (userAgent.os.name === 'Android') {
        setDevice({
            model: `${userAgent.device.vendor} ${userAgent.device.model}`,
            type: userAgent.device.type,
        });
    } else if (userAgent.os.name === 'iOS') {
        setDevice({
            model: userAgent.os.name,
            type: userAgent.device.type,
        });
    } else if (userAgent.os.name === 'Windows Phone') {
        setDevice({
            model: userAgent.os.name,
            type: userAgent.device.type,
        });
    } else {
        setDevice({
            model: 'Unknown Device',
            type: 'desktop',
        });
    }
};

export default function Login({ history }) {
    const isDefault = useMediaQuery({
        query: '(min-width: 1024px)',
    });
    const isMobileOrTablet = useMediaQuery({
        query: '(max-width: 1023px)',
    });
    const [input, setInput] = useState({
        email: '',
        password: '',
        extendValidity: '',
    });
    // console.log(input);
    const navigate = useNavigate();
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const context = useContext(AuthContext);
    const { login } = context;
    const token = Cookies.get('jwtToken');
    const [loading, setLoading] = useState(false); // this is for loading state
    const [loadingState, setLoadingState] = useState(false);
    const [msgSubt, setMsgSubt] = useState('');
    const [msgTitle, setMsgTitle] = useState('');
    const [incorrectCredentials, setIncorrectCredentials] = useState(false); // this is for loading state
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [keyUp, setKeyUp] = useState('', false);
    const parser = new UAParser();
    const [userAgent, setUserAgent] = useState(parser.getResult());
    const [device, setDevice] = useState({
        model: '',
        type: '',
    });
    const [address, setAddress] = useState('');

    function handleWindowResize() {
        setWindowSize(getWindowSize());
    }

    const getUserIP = async () => {
        const result = await publicIpv4();
        setAddress(result);
    };

    useEffect(() => {
        if (token) {
            navigate('/live/mapOverview', { replace: true });
        } else {
            getUserAgent(userAgent, setDevice);
            getUserIP();

            window.addEventListener('resize', handleWindowResize);
            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        }
    }, []);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const btnLogin = async (e) => {
        setLoadingState(true);
        if (!input.email) {
            if (!input.password) {
                setEmailError(true);
                setPasswordError(true);
                setEmailErrorMessage('Email address is required.');
                setPasswordErrorMessage('Password is required.');
                setLoadingState(false);
            } else {
                setEmailError(true);
                setPasswordError(false);
                setEmailErrorMessage('Invalid email address.');
                setPasswordErrorMessage('');
                setLoadingState(false);
            }
        } else if (!isValidEmail(input.email) && (!input.password)) {
            setEmailError(true);
            setPasswordError(true);
            setEmailErrorMessage('Invalid email address.');
            setPasswordErrorMessage('Password is required.');
            setLoadingState(false);
        } else if (isValidEmail(input.email) && (!input.password)) {
            setEmailError(false);
            setPasswordError(true);
            setEmailErrorMessage('');
            setPasswordErrorMessage('Password is required.');
            setLoadingState(false);
        } else if (!isValidEmail(input.email) && (input.password)) {
            setEmailError(true);
            setPasswordError(false);
            setEmailErrorMessage('Invalid email address.');
            setPasswordErrorMessage('');
            setLoadingState(false);
        } else {
            setEmailError(false);
            setPasswordError(false);
            const reducer = {
                email: Base64.encode(input.email),
                password: Base64.encode(input.password),
                extendValidity: input.extendValidity,
                device: device,
                address: address,
            };
            // console.log(reducer);
            const result = await APIPostLogin(setLoading, reducer);
            try {
                // console.log('LOGIN RESULT: ', result);
                if (result.data.status === 200) {
                    const { data } = result.data;
                    if (data.user.privileges.length > 1) {
                        navigate('/agencySelector', { replace: true, state: data });
                        // history.push('./agencySelector', data);
                        // console.log(data);
                        setLoadingState(false);
                    } else {
                        try {
                            const websocketConnection = await APIWSConnect(data.tokens, data.user.privileges[0].agency.oid);
                            const selectionList = {
                                agencyUUID: data.user.privileges[0].agency.oid,
                                id: data.user.privileges[0].agency.agencyId,
                                name: data.user.privileges[0].agency.agencyName,
                                role: data.user.privileges[0].role,
                            };
                            if (websocketConnection.status === 200) {
                                login(data, null, selectionList, data.user.privileges);
                                // history.push('/dashboard/live/mapOverview');
                                navigate('/live/mapOverview', { replace: true });
                                setLoading(false);
                            } else {
                                setLoading(false);
                                setIncorrectCredentials(true);
                                setMsgTitle('Invalid credential');
                                setMsgSubt(`${websocketConnection.status} : Please try again later`);
                            }
                            setIncorrectCredentials(false);
                            setLoadingState(false);
                        } catch (err) {
                            // console.log('error calling websocket', err);
                            setIncorrectCredentials(true);
                            setMsgTitle('Invalid credential');
                            setMsgSubt(`Unable to connect to websocket : ${err}, please try again later`);
                            setLoadingState(false);
                        }
                    }
                }
            } catch (err) {
                console.log('this is error from login submit catch', err);
                console.log(result);
                const { response } = result;
                if (isMobileOrTablet) {
                    if (response.status === 404) {
                        setIncorrectCredentials(true);
                        setEmailError(true);
                        setEmailErrorMessage(response.data.message);
                        setLoading(false);
                        setLoadingState(false);
                    } else {
                        setIncorrectCredentials(true);
                        setPasswordError(true);
                        setPasswordErrorMessage(response.data.message);
                        setEmailError(true);
                        setEmailErrorMessage(response.data.message);
                        setLoading(false);
                        setLoadingState(false);
                    }
                } else {
                setIncorrectCredentials(true);
                setMsgSubt(result.response.data.error.message);
                setMsgTitle('Invalid Credentials');
                setLoading(false);
                setLoadingState(false);
                }
            }
        }
    };

    useEffect(() => {
        // console.log('KWY', keyUp);
        if (keyUp !== '') {
            btnLogin();
        }
    }, [keyUp]);

    const activateNow = (e) => {
        // history.push('/404');
    };

    const forgotPassWord = (e) => {
        navigate('/forgotPassword', { replace: true });
    };

    const emailOnChangeFunction = (e) => {
        setInput({ ...input, email: e.target.value });
    };

    const passwordOnChangeFunction = (e) => {
        setInput({ ...input, password: e.target.value });
    };

    if (token) {
        return null;
    }

    return (
        token ? (
            <></>
        ) : (
            <>
                {isMobileOrTablet && (
                    <section className="login-mainMobile" style={{ height: document.documentElement.clientHeight }}>
                        <div className="login-row-mobile">
                            <div className="login-container-mobile">
                                <div className="login-header-container">
                                    <div className="login-header-mobile">
                                        {/* TO BE ADDED/CONFIGURED */}
                                        <img src={HeaderImage} className="logo" alt="header-logo" />
                                        <img src={AMLogo} className="logo-mobile-alt" alt="header-logo" />
                                        <div className="login-header-content-mobile">
                                            <Title title="Log in" subtitle="Welcome back! Please enter your details." titleSize="1.5rem" subtitleSize="1rem" gap="0.5rem" />
                                        </div>
                                    </div>
                                    <div className="login-content-mobile">
                                        <div className="login-form-mobile">
                                            <div className="input-container">
                                                <TextInput label="Email" placeholder="Enter your email" setInput={setInput} input={input} name="email" type="text" error={emailError} setError={setEmailError} keyUp={keyUp} setKeyUp={setKeyUp} onChangeFunction={emailOnChangeFunction} />
                                                {emailError && (
                                                    <div className="email-password-error">{emailErrorMessage}</div>
                                                )}
                                            </div>
                                            <div className="input-container">
                                                <TextInput label="Password" placeholder="Enter your password" setInput={setInput} input={input} name="password" type="password" error={passwordError} setError={setPasswordError} keyUp={keyUp} setKeyUp={setKeyUp} onChangeFunction={passwordOnChangeFunction} />
                                                {passwordError && (
                                                    <div className="email-password-error">{passwordErrorMessage}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="login-checkbox-row-mobile">
                                            <div className="login-checkbox">
                                                <Checkbox width="20px" height="20px" checked={input.extendValidity} onClick={() => setInput((prev) => ({ ...prev, extendValidity: !prev.extendValidity }))} />
                                                <div className="login-checkbox-label">
                                                    <Text className="login-checkbox-label-style" text="Remember for 30 days" size="0.875rem" color="#344054" weight="500" align="left" />
                                                </div>
                                            </div>
                                            <div className="forgot-password-button">
                                                <TextButton label="Forgot password" onClickFunction={forgotPassWord} fontWeight={600} color="#26404E" />
                                            </div>
                                        </div>
                                        <div className="login-actions-mobile">
                                            <PrimaryButton label="Sign in" width="100%" onClickFunction={btnLogin} loading={loadingState} disable={loadingState} />
                                        </div>
                                    </div>
                                </div>
                                <div className="login-footer-container">
                                    <div className="login-footer-mobile">
                                        <Title
                                          width="100%"
                                          textAlign="center"
                                          margin="auto"
                                          title={`HRZNv${process.env.REACT_APP_VERSION} Build ${process.env.REACT_APP_BUILD_SHA}`}
                                          subtitle={`Copyright © ${new Date().getFullYear()} Asia Mobility Technologies. All Rights Reserved.`}
                                          titleSize="0.875rem"
                                          subtitleSize="0.875rem"
                                          gap="0rem"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                {isDefault && (
                    <div className="login-row" style={{ minHeight: windowSize.innerHeight, minWidth: windowSize.innerWidth }}>
                        <div className="login-column">
                            <div className="login-container">
                                <div className="login-content">
                                    <div className="login-logo">
                                        {/* Horizon Logo */}
                                        <img src={HeaderImage} className="logo" alt="header-logo" />
                                        {/* Asia Mobiliti Logo */}
                                        <img src={AMLogo} className="login-logo-alt" alt="header-logo" />
                                    </div>
                                    <div className="login-form">
                                        <div className="login-form-header">
                                            <Title title="Log in" subtitle="Welcome back! Please enter your details." titleSize="2.25rem" subtitleSize="1rem" gap="0.75rem" />
                                        </div>
                                        <div className="login-form-content">
                                            <div className="login-form-input">
                                                <div className="input-container">
                                                    <TextInput label="Email" placeholder="Enter your email" setInput={setInput} input={input} name="email" type="text" error={emailError} setError={setEmailError} keyUp={keyUp} setKeyUp={setKeyUp} onChangeFunction={emailOnChangeFunction} />
                                                    {emailError && (
                                                        <div className="email-password-error">{emailErrorMessage}</div>
                                                    )}
                                                </div>
                                                <div className="input-container">
                                                    <TextInput label="Password" placeholder="Enter your password" setInput={setInput} input={input} name="password" type="password" error={passwordError} setError={setPasswordError} keyUp={keyUp} setKeyUp={setKeyUp} onChangeFunction={passwordOnChangeFunction} />
                                                    {passwordError && (
                                                        <div className="email-password-error">{passwordErrorMessage}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="login-form-checkbox">
                                                <div className="login-checkbox">
                                                    {/* <input type="checkbox" className="login-checkbox-input" checked={input.extendValidity} onChange={() => setInput((prev) => ({ ...prev, extendValidity: !prev.extendValidity }))} /> */}
                                                    <Checkbox width="20px" height="20px" checked={input.extendValidity} onClick={() => setInput((prev) => ({ ...prev, extendValidity: !prev.extendValidity }))} />
                                                    <div className="login-checkbox-label">
                                                        <Text className="login-checkbox-label-style" text="Remember for 30 days" size="0.875rem" color="#344054" weight="500" align="left" />
                                                    </div>
                                                </div>
                                                <div className="forgot-password-button">
                                                    <TextButton label="Forgot password" onClickFunction={forgotPassWord} fontWeight={600} color="#26404E" />
                                                </div>
                                            </div>
                                            <div className="login-action">
                                                <PrimaryButton label="Sign in" width="22.5rem" onClickFunction={btnLogin} loading={loadingState} disable={loadingState} />
                                                {/* <GoogleButton label="Sign in with Google" width="22.5rem" /> */}
                                            </div>
                                        </div>
                                        {/* <div className="login-form-starter-kit">
                            <Text className="login-row-text" size="0.875rem" weight="400" color="#475467" text="Received a Starter Kit?" align="left" />
                            <TextButton label="Activate now" onClickFunction={activateNow} fontWeight={600} color="#26404E" />
                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="login-footer">
                                <Title title={`HRZNv${process.env.REACT_APP_VERSION} Build ${process.env.REACT_APP_BUILD_SHA}`} subtitle={`Copyright © ${new Date().getFullYear()} Asia Mobility Technologies. All Rights Reserved.`} titleSize="0.875rem" subtitleSize="0.875rem" gap="0rem" />
                            </div>
                        </div>
                        <div className="login-image">
                            <div className="login-wrapper">
                                <div className="login-text-container">
                                    <div className="login-text-background">
                                        <div className="login-text">
                                            Enterprise-ready, database-per-tenant IoT platform and API for management of assets, users and data
                                        </div>
                                        <div className="powered-text">
                                            <Title title="" subtitle="Powered by" titleSize="0rem" subtitleSize="1rem" gap="0rem" />
                                            <img
                                              src={asiamobiliti}
                                              loading="lazy"
                                              alt="Asia Mobiliti logo"
                                              style={{
                                                height: '1rem',
                                                paddingTop: '0.2rem',
                                                paddingLeft: '0.2rem',
                                              }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {incorrectCredentials && (
                        <div className="notification">
                            <SnackBar state={incorrectCredentials} setState={setIncorrectCredentials} title={msgTitle} subtitile={msgSubt} module="Login" action="error" />
                        </div>
                        )}
                    </div>
                )}
            </>
        )
    );
}
