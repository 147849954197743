/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import Cookies from 'js-cookie';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import MotionState from '../motionState/MotionState';
import TruckAssetIcon from './img/truckAssets.svg';
import TimeIcon from './img/time.svg';
import AddressIcon from './img/address.svg';
import GPSFixIcon from './img/gpsfix.svg';
import NolocationServiceIcon from './img/noLocationService.svg';
import WifiIcon from './img/wifi.svg';
import BeaconIcon from './img/beaconConnection.svg';
import SpeedometerIcon from './img/speedometer.svg';
import PointerIcon from './img/pointer.svg';
import BatteryIcon from './img/battery.svg';
import SignalIcon from './img/signal.svg';
import OverspeedIcon from './img/overspeed.svg';
import InactiveIcon from './img/inactive.svg';
import AssetUtiliseIcon from './img/assetUtilise.svg';
import OrangeClockRewind from './img/clockRewind.svg';
import HighSignalIcon from './img/highSignal.svg';
import MediumSignalIcon from './img/mediumSignal.svg';
import LowSignalIcon from './img/lowSignal.svg';
import NoTelemetryIcon from './img/featureIcon.svg';
import CloseButtonIcon from './img/closeButton.svg';
import OverChargeIcon from './img/overcharge.svg';
import GasStationIcon from './img/gas-station.svg';
import MileageIcon from './img/mileage.svg';
import LowLevel from './img/lowLevel.svg';
import {
    LatestDataButton, AssetCardButton,
} from '../buttonComponent/ButtonComponent';
import ToolTips from '../tooltip/ToolTips';
import { APISendTeltonikaCommand, APIGetDevices } from '../../config/restAPI/FetchAPI';
import './assetDetailsCard.css';

export default function AssetCardMore(props) {
    const {
        markerValue, timeStampDisplay, closeButton, getLatestDataButton, lastSeenTimestamp, assetSelection,
    } = props;

    // console.log('assetSelection :', assetSelection);
    const navigate = useNavigate();
    const [hover, setHover] = useState(false);
    const [loading, setLoading] = useState(false);
    const [displayLastSeen, setDisplayLastSeen] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);
    const textElementRef = useRef();
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agencyUUID');

    const isDefault = useMediaQuery({
        query: '(min-width: 1024px)',
    });
    const isMobileOrTablet = useMediaQuery({
        query: '(max-width: 1023px)',
    });
    // console.log(markerValue);

    const compareSize = () => {
        if (textElementRef && textElementRef.current && textElementRef.current.scrollWidth && textElementRef.current.clientWidth) {
            const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
            // console.log('compare: ', compare);
            setHover(compare);
        }
    };

    const fetchDevice = async (value) => {
        const params = {
            oid: value.deviceOid,
            pageNumber: 1,
        };
        try {
            const result = await APIGetDevices(setLoading, token, agencyId, params);
            if (result.data.status === 200) {
                const { data } = result.data;
                const [{
                    oid, assetName, assetType, deviceSerial, tags, deviceType, lastUpdated, dataUsage, dataLimit, description,
                }] = data;
                if (data.length > 0) {
                    const assetDetails = {
                        id: oid,
                        deviceSN: deviceSerial,
                        assetName: assetName !== null ? (assetName) : (null),
                        assetType: [assetType],
                        tags: tags !== undefined ? (tags) : [],
                        dataUsage: {
                            usage: dataUsage !== null ? (dataUsage) : (null),
                            limit: dataLimit,
                        },
                        lastUpdated: {
                            date: lastUpdated !== null ? (moment(lastUpdated).format('DD MMM YYYY')) : (null),
                            time: lastUpdated !== null ? (`${moment(lastUpdated).format('h:mm:ssa')} GMT${moment(lastUpdated).format('ZZ').replace('00', '').replace('0', '')}`) : (null),
                        },
                        description: description || '',
                    };
                    navigate('/inventory/assetdevices/configuration', { replace: true, state: assetDetails });
                    setLoading(false);
                }
            }
        } catch (err) {
            console.log('fetch error', err);
        }
    };

    const [devicePreferences, setDevicePreferences] = useState(null);

    // Function to fetch fuel and mileage source for device
    const fetchFuelAndMileageSource = async (value) => {
        const params = {
            oid: value.deviceOid,
            pageNumber: 1,
        };
        try {
            const result = await APIGetDevices(setLoading, token, agencyId, params);
            if (result.data.status === 200) {
                const { data } = result.data;
                if (data.length > 0) {
                    const [{
                        oid, assetName, deviceSerial, fuelSource, mileageSource, fuelCapacity,
                    }] = data;
                    const assetDetails = {
                        id: oid,
                        deviceSN: deviceSerial,
                        assetName: assetName || null,
                        fuelSource: fuelSource,
                        mileageSource: mileageSource,
                        fuelCapacity: fuelCapacity,
                    };
                    setDevicePreferences(assetDetails);
                }
            }
        } catch (err) {
            console.log('fetch error', err);
        }
    };

    useEffect(() => {
        if (markerValue && markerValue.deviceOid) {
            fetchFuelAndMileageSource(markerValue);
        }
    }, [markerValue]);

    const getSourceData = (data, sourceType, key) => {
        const cachedData = data?.cachedData || {};
        if (sourceType && cachedData[sourceType]) {
            return cachedData[sourceType][key] || null;
        }
        return null;
    };

    // Fuel Level and Fuel Level Int
    const fuelLevel = getSourceData(markerValue, devicePreferences?.fuelSource, 'fuelLevel');
    const fuelLevelInt = getSourceData(markerValue, devicePreferences?.fuelSource, 'oemFuelLevelInt')
        || getSourceData(markerValue, devicePreferences?.fuelSource, 'fuelLevelInt');

    // Calculation for fuelLevel in percentage
    let computedFuelLevelPercentage = fuelLevel;
    if (fuelLevel == null) {
        const fuelCapacity = devicePreferences?.fuelCapacity;
        if (fuelLevelInt != null && fuelCapacity > 0) {
            computedFuelLevelPercentage = (fuelLevelInt / fuelCapacity) * 100;
        } else {
            computedFuelLevelPercentage = null;
        }
    }

    // Mileage
    const mileage = getSourceData(markerValue, devicePreferences?.mileageSource, 'oemTotalMileageInt')
        || getSourceData(markerValue, devicePreferences?.mileageSource, 'vehicleMileage')
        || getSourceData(markerValue, devicePreferences?.mileageSource, 'odometer');

    // compare once and add resize listener on "componentDidMount" (this for elipsis event listener)
    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
    }, []);

    // remove resize listener again on "componentWillUnmount" (this for elipsis event listener)
    useEffect(() => () => {
        window.removeEventListener('resize', compareSize);
    }, []);

    const geoLocationStatus = (geolocation) => {
        switch (geolocation) {
            case 0:
                return 'GPS';
            case 16:
                return 'Wifi';
            case 17:
                return 'NoWifi';
            default:
                return 'Garbage';
        }
    };

    const checkHistoryLocationStatus = () => {
        if (markerValue.beacon) {
            if (geoLocationStatus(markerValue.geolocationStatus) === 'NoWifi' || geoLocationStatus(markerValue.geolocationStatus) === 'Garbage') {
                setDisplayLastSeen(false);
            }
        } else if (markerValue.geolocationStatus) {
                // this to check if status is GPS
                if ((geoLocationStatus(markerValue.geolocationStatus) === 'GPS') || (geoLocationStatus(markerValue.geolocationStatus) === 'Wifi')) {
                    setDisplayLastSeen(false); // not display the history location

                  // to check if the status noWifi or garbage
                } else if ((geoLocationStatus(markerValue.geolocationStatus) === 'NoWifi') || (geoLocationStatus(markerValue.geolocationStatus) === 'Garbage')) {
                    // if lastSeenTimestamp, lat and lon have value
                    if (markerValue.lastSeenTimestamp && markerValue.latitude && markerValue.longitude) {
                        setDisplayLastSeen(true); // display the history location
                    } else {
                        setDisplayLastSeen(false); // not display the history location
                    }
                } else {
                    setDisplayLastSeen(false); // not display the history location
                }
            } else {
                setDisplayLastSeen(false); // not display the history location
            }
    };

    useEffect(() => {
        if (Object.keys(markerValue).length > 0) {
            checkHistoryLocationStatus();
        }
    }, [markerValue]);

   const signalIcon = (signal) => {
    if (signal) {
            if (signal > 80) {
                return <img loading="lazy" src={HighSignalIcon} alt="lqiEx" style={{ display: 'flex', alignItems: 'center' }} />;
            } if (signal <= 79 && signal > 40) {
                return <img loading="lazy" src={MediumSignalIcon} alt="lqiG" />;
            }
                return <img loading="lazy" src={LowSignalIcon} alt="lqiP" />;
        }
            return null;
    };

    // logic for signal text
    const signalText = (signal) => {
        if (signal) {
            if (signal > 80) {
                return 'Good Signal Quality';
            } if (signal <= 79 && signal > 40) {
                return 'Average Signal Quality';
            }
                return 'Poor Signal Quality';
        }
            return null;
    };

    // logic for geolocationStatus img
    const geolocationStatuImg = (status, beacon) => {
        if (beacon) {
            return <img loading="lazy" src={BeaconIcon} alt="beacon-icon" />;
        }
            switch (status) {
                case 0:
                    return <img loading="lazy" src={GPSFixIcon} alt="gps-fix" />;
                case 16:
                    return <img loading="lazy" src={WifiIcon} alt="no-location" />;
                case 17:
                    return <img loading="lazy" src={NolocationServiceIcon} alt="NolocationServiceIcon" />;
                default:
                    return <img loading="lazy" src={NolocationServiceIcon} alt="NolocationServiceIcon" />;
            }
    };

    // logic for geolocationStatus text
    const geoLocationStatusText = (status, beacon) => {
        if (beacon) {
            return 'Indoor Beacon';
        }
            switch (status) {
                case 0:
                    return 'GPS Fix';
                case 16:
                    return 'Wi-Fi Geolocation';
                case 17:
                    return 'No Location Service';
                default:
                    return 'No Location Service';
            }
    };

    // logic for geolocationDetailText
    const geoLocationDetailText = (status, nsat, geoAccuracy, beacon) => {
        if (beacon) {
            return `${beacon.locationName}`;
        }
            switch (status) {
                case 0:
                    return `${nsat} Satellites`;
                case 16:
                    return `Accurate to ${geoAccuracy} Meters`;
                default:
                    return '';
            }
    };

    const inActiveStats = () => {
        const assetTimestamp = markerValue.timestamp;
        const curretTimestamp = moment().format('YYYY-MM-DD HH:mm:ss');
        const diff = moment(curretTimestamp).diff(assetTimestamp, 'days');
        // this to check if asset timestamp are more than 7 days
        if (diff > 7) {
            return true;
        }
            return false;
    };

    const btnRefreshData = async (e) => {
        e.preventDefault();
        try {
            const reducer = { deviceIMEI: markerValue.deviceSerial };
            const result = await APISendTeltonikaCommand(reducer, token, agencyId, setApiLoading);
            if (result === 429) {
                console.log('too many request, please try again later');
                // setErrOpen(true);
                // setErrMsg("Too many requests. Please try again later");
                // setErrSevere("error");
            } else if (result.data.status === 200) {
                console.log('Command sent successfully');
                // setErrOpen(true);
                // setErrMsg("Command sent successfully");
                // setErrSevere("success");
            } else {
                console.log('something went wrong please try again later');
                // setErrOpen(true);
                // setErrMsg("Something went wrong. Please try again later");
                // setErrSevere("error");
            }
        } catch (err) {
            console.log('something went wrong please try again later');
            // setErrOpen(true);
            // setErrMsg("Something went wrong. Please try again later");
            // setErrSevere("error");
        }
    };

    const insightsNavigate = (params) => {
        const uuid = params.deviceOid;
        navigate('/insights/assetPerformance', { replace: true, state: { uuid } });
    };

    const telemetryNavigate = (params) => {
        const uuid = params.deviceOid;
        navigate('/live/telemetrySnapshot', { replace: true, state: { uuid } });
    };

    return (
        <div className={isMobileOrTablet ? 'asset-card-detail-container-mobile' : 'asset-card-detail-container'}>
        <div className="asset-card-header-container">
            <div className="asset-card-device-container">
                <img loading="lazy" src={TruckAssetIcon} alt="truck-icon" />
                <ToolTips text={markerValue.assetName} disbledHover={!hover}>
                    <div ref={textElementRef} className="asset-card-header-text">{markerValue.assetName ? markerValue.assetName : '-'}</div>
                </ToolTips>
                <MotionState motionInference={markerValue.motionInference} />
                <img loading="lazy" src={CloseButtonIcon} alt="close-button" style={{ cursor: 'pointer' }} className="asset-card-close-button" onClick={(e) => closeButton(e)} />
            </div>
            <div className="asset-device-detail-container">{markerValue.deviceSerial ? `Device ${markerValue.deviceSerial}` : '-'}</div>
            <div className="asset-card-time-container ">
                <img loading="lazy" src={TimeIcon} alt="time-icon" />
                <div className="asset-card-detail-text">{markerValue.timestamp ? `Last updated ${timeStampDisplay}` : 'No Recent Activity'}</div>
                { inActiveStats() && <img loading="lazy" src={InactiveIcon} alt="inactive" /> }
            </div>
        </div>
        <div className="asset-card-content-container">
            <div className="asset-card-address-container">
                <img loading="lazy" src={AddressIcon} alt="address-icon" />
                <div className="asset-card-address-text">
                    {
                        displayLastSeen && (
                        <div className="asset-card-last-seen-container">
                            <img loading="lazy" src={OrangeClockRewind} alt="orange-clock-rewind" />
                            <div className="asset-card-last-seen-text">{`Last seen ${lastSeenTimestamp}`}</div>
                        </div>
                        )
                    }
                    <div className="asset-card-detail-text">{markerValue.address ? markerValue.address.localDisplay : '-'}</div>
                    <a className="asset-card-lat-lon-text" href={`https://www.google.com/maps/search/?api=1&query=${markerValue.latitude}, ${markerValue.longitude}`} rel="noopener noreferrer" target="_blank">{markerValue.latitude || markerValue.longitude ? `${markerValue.latitude}, ${markerValue.longitude}` : '-'}</a>
                </div>
            </div>
            <div className="asset-card-geolocation-container">
                <div className="asset-card-info-detail">
                    {geolocationStatuImg(markerValue.geolocationStatus, markerValue.beacon)}
                    <div className="asset-card-address-text">
                        <div className="asset-card-detail-text">{geoLocationStatusText(markerValue.geolocationStatus, markerValue.beacon)}</div>
                        <div className="asset-device-detail-container">{geoLocationDetailText(markerValue.geolocationStatus, markerValue.nSat, markerValue.geoAccuracy, markerValue.beacon)}</div>
                    </div>
                </div>
                {/* <div className="asset-card-info-detail">
                    <img src={PointerIcon} alt="pointer-icon" />
                    <div className="asset-card-address-text">
                        <div className="asset-card-detail-text">-</div>
                        <div className="asset-device-detail-container">Recorded Distance</div>
                    </div>
                </div> */}
            </div>
            <div className="asset-card-address-container">
                <img loading="lazy" src={SpeedometerIcon} alt="address-icon" />
                <div className="asset-card-address-text-main">
                    <div className="asset-card-detail-text">{markerValue.kmh ? `${markerValue.kmh.toFixed(2)} km/h` : '-'}</div>
                    {markerValue.kmh > 101 && <img loading="lazy" src={OverspeedIcon} alt="overspeed" /> }
                    {/* <img src={OverspeedIcon} alt="overspeed" /> */}
                </div>
            </div>
        </div>
        <div className="asset-card-content-container">
            <div className="asset-card-geolocation-container">
                <div className="asset-card-info-detail">
                    <img loading="lazy" src={BatteryIcon} alt="gpsFixIcon" />
                    <div className="asset-card-address-text">
                        <div className="asset-card-detail-text">
                            {markerValue.avgDcVoltPerc ? `${markerValue.avgDcVoltPerc.toFixed(0)} %` : '-'}
                            {markerValue.dcVolt > 84.1 && <img loading="lazy" src={OverChargeIcon} alt="overcharged" />}
                        </div>
                        <div className="asset-device-detail-container">
                            {markerValue.dcVolt > 84.1 && '>' }
                            {markerValue.dcVolt ? `${markerValue.dcVolt.toFixed(2)} volts` : ''}
                        </div>
                    </div>
                </div>
                <div className="asset-card-info-detail">
                    <img loading="lazy" src={SignalIcon} alt="pointer-icon" />
                    <div className="asset-card-address-text">
                        <div className="asset-card-signal-container">
                            <div className="asset-card-detail-text">{markerValue.operatorName ? markerValue.operatorName : '-'}</div>
                            {signalIcon(markerValue.signalQuality)}
                        </div>
                        <div className="asset-device-detail-container">{signalText(markerValue.signalQuality)}</div>
                    </div>
                </div>
            </div>
            <div className="asset-card-geolocation-container">
                {/* Fuel Level Indicator */}
                <div className="asset-card-info-detail">
                    <img loading="lazy" src={GasStationIcon} alt="gpsFixIcon" />
                    <div className="asset-card-address-text">
                        <div className="asset-card-detail-text">
                            {computedFuelLevelPercentage !== null
                                ? `${computedFuelLevelPercentage.toFixed(0)} %`
                                : '-'}
                            {computedFuelLevelPercentage !== null && computedFuelLevelPercentage < 20 && (
                                <img loading="lazy" src={LowLevel} alt="low-fuel" />
                            )}
                        </div>
                        <div className="asset-device-detail-container">
                            {fuelLevelInt !== null
                                ? `${fuelLevelInt.toFixed(2)} litres`
                                : '-'}
                        </div>
                    </div>
                </div>
                {/* Total Mileage Indicator */}
                <div className="asset-card-info-detail">
                    <img loading="lazy" src={MileageIcon} alt="pointer-icon" />
                    <div className="asset-card-address-text">
                        <div className="asset-card-signal-container">
                            <div className="asset-card-detail-text">
                                {mileage !== null
                                    ? `${mileage.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })} KM`
                                    : '-'}
                            </div>
                        </div>
                        <div className="asset-device-detail-container">Total Mileage</div>
                    </div>
                </div>
            </div>
            {/* <div className="asset-card-address-container">
                <img src={AssetUtiliseIcon} alt="address-icon" />
                <div className="asset-card-address-text">
                    <div className="asset-card-detail-text">-</div>
                    <div className="asset-device-detail-container">Average Utilisation Rate</div>
                </div>
            </div> */}
        </div>
        <div className="asset-card-buttons-container">
            {/* <LatestDataButton label="Refresh Data" refreshClick={btnRefreshData} disable /> */}
            <AssetCardButton indicator="telemetry" navigateButton={() => telemetryNavigate(markerValue)} />
            <AssetCardButton indicator="assetStatus" navigateButton={() => insightsNavigate(markerValue)} />
            <AssetCardButton indicator="truck" navigateButton={() => fetchDevice(markerValue)} />
        </div>
        </div>
    );
}
